h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-heading);
  color: var(--primary-text-color);
}

h1 {
  font-size: 30px;
  letter-spacing: 0.28px;

  // Hack to style super title for now - add <span>Text</span> above title in cms
  span {
    display: block;
    font-size: 18px;
    margin-bottom: 18px;
  }
}

h2 {
  font-size: 24px;
  letter-spacing: 0.24px;
}

h3 {
  font-size: 18px;
  letter-spacing: 0.18px;
}

h4 {
  font-size: 15px;
  letter-spacing: 0.15px;
}

h5 {
  font-size: 14px;
  letter-spacing: 0.14px;
}

p {
  font-size: 14px;
  letter-spacing: 0;
  color: var(--primary-text-color);
  font-family: var(--font-body);

  &.base-copy {
    font-size: 14px;
    line-height: 2;
  }
}
