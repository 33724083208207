@import "mixins.scss";

@include font("Lora", "/assets/fonts/lora/Lora-VariableFont_wght", "ttf");

@include font(
  "ca-geheimagent",
  "/assets/fonts/ca-geheimagent/CA Geheimagent",
  "otf"
);

@include font("bodoni-72-book", "/assets/fonts/bodoni/Bodoni-72-Book", "ttf");


@include font("gothic", "/assets/fonts/Gothic 520 HPLHS", "ttf");

@include font("yucca", "/assets/fonts/Yucca", "otf");

@mixin helv {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}
