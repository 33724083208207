
@mixin background(
  $bgImage,
  $bgColor: $black,
  $bgPosition: center center,
  $bgSize: cover,
  $bgRepeat: no-repeat
) {
  background: {
    image: $bgImage;
    color: $bgColor;
    position: $bgPosition;
    size: $bgSize;
    repeat: $bgRepeat;
  }
}

@mixin font($font-family, $font-file, $extension) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + "." + $extension);
    font-weight: normal;
    font-style: normal;
  }
}
