@import "styles/fonts.scss";

:root {
  /* Default Houdini color palette */
  --white: #ffffff;
  --black: #000000;
  --charcoal: #222222;
  --gray-1: #fdfdfd;
  --gray-2: #f6f6f6;
  --gray-3: #e6e6e6;
  --gray-4: #999999;
  --gray-5: #7b7b7b;
  --gray-6: #5c5c5c;
  --gray-7: #444444;
  --gray-8: #333333;
  --pink: #e7a8e9;

  /* Default theme variables */
  --primary-color: var(--pink);
  --primary-text-color: var(--white);
  --default-page-bg-color: var(--charcoal);
  /*--default-page-bg-img: url('/assets/images/backgrounds/background-default.jpg');*/
  --primary-button-bg-color: var(--primary-color);
  --primary-button-text-color: var(--charcoal);
  --icon-btn-color: var(--primary-color);
  --default-warning-bg-color: var(--charcoal);
  --default-warning-text-color: var(--primary-text-color);
  --camera-feed-page-blur-bg-color: var(--charcoal);
  --camera-feed-page-photo-scope-shadow-color: var(--charcoal);
  --camera-feed-page-photo-scope-border-color: var(--white);
  --camera-feed-page-photo-scope-crosschair-color: var(--white);
  --camera-feed-page-bottom-controls-selected-effect-border-color: var(--white);
  --camera-feed-page-bottom-controls-effect-bg-color: var(--white);
  // --modal-bg-color: var(--gray-8);
  // --modal-title-color: var(--primary-text-color);
  // --modal-body-color: var(--primary-text-color);
  // --modal-close-button-times-color: var(--gray-8);
  // --modal-overlay-color: #00000080;
  // --modal-shadow-color: #000000a3;
  --submission-page-form-color: var(--primary-text-color);
  --submission-page-preview-image-box-bg-color: var(--gray-7);
  --default-input-bg-color: var(--gray-7);
  --default-input-text-color: var(--primary-text-color);
  --default-input-placeholder-text-color: var(--gray-4);
  --default-input-focus-placeholder-text-color: var(--primary-text-color);
  --invalid-input-text-color: var(--primary-color);
  --invalid-input-placeholder-text-color: var(--primary-color);
  --invalid-input-star-color: var(--primary-color);
  --thank-you-page-message-text-color: var(--primary-text-color);

  --font-heading: news-gothic-std, 'News Gothic Std', News Gothic Std, sans-serif;
  --font-body: Helvetica, Helvetica Neue, Arial, sans-serif;
  --font-buttons: news-gothic-std, 'News Gothic Std', News Gothic Std, sans-serif;
}

* {
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

input {
  user-select: text;
}

label,
input[type="checkbox"] {
  cursor: pointer;
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: Lora, Arial, Helvetica, sans-serif;
}

@import "styles/typegraphy.scss";
